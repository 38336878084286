<template>
  <v-card>
    <iframe width="100%" height="800" class="pa-2" :src="payment_url"></iframe>
  </v-card>
</template>

<script>
export default {
  emits: ["close"],
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    payment_url: {
      type: String,
      default: "",
    },
  },
  methods: {
    receiveMessage(event) {
      console.log(event.data);
    },
  },
  mounted() {
    window.addEventListener("message", this.receiveMessage);
  },
  beforeDestroy() {
    this.$store.dispatch("payment/loadFees");
    this.$store.dispatch("payment/fetchPaymentHistory");
  },
};
</script>

<style>
</style>